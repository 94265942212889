<template>
  <div>
    <b-card title="📃 تقارير الحضور والغياب والتأخر لفترة">
      <div class="row">
        <div class="col-12 col-lg-4 c">
          <div class="form-group">
            <h5 for="">الفترة من</h5>
            <input
              type="date"
              class="form-control form-control-lg"
              ref="from"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 c">
          <div class="form-group">
            <h5 for="">الفترة إلى</h5>
            <input type="date" class="form-control form-control-lg" ref="to" />
          </div>
        </div>
        <div class="col-12"></div>
        <div class="col-12 col-lg-6 c text-center">
          <button class="btn btn-lg btn-primary" @click="viewReport()">
            عرض التقرير الآن
          </button>
        </div>
      </div>
    </b-card>
    <b-card v-if="table">
      <div class="col-12 text-center g" v-if="loading">
        <img
          src="../../assets/images/loading.svg"
          style="width: 60px; height: 60px"
          alt=""
        />
        <br />
        جاري البحث..
      </div>
      <div class="col-12 g text-center" v-if="reports.length > 0">
        <button class="btn btn-info" @click="print()">طباعة الجدول</button>&nbsp;
        <button class="btn btn-success" @click="exportExcel()">تصدير الجدول</button>
        <br />
        <div class="col-12 col-lg-6 c g text-center">
          <div class="form-group">
            <label for="">فرز بمجموعة الإعدادات</label>
            <select
              class="form-control"
              name=""
              v-model="selected_group"
              @change="ga()"
              id=""
            >
              <template v-for="group in groups">
                <option :value="group.code" :key="group.code">
                  {{ group.title }}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div
        class="col-12 table-responsive"
        ref="table"
        v-if="reports.length > 0"
      >
        <table class="table table-hover table-striped" id="table">
          <thead>
            <th>المعلم</th>
            <th>عدد أيام الحضور</th>
            <th>ايام التأخر</th>
            <th>أيام الغياب</th>
            <th>ساعات التأخر</th>
            <th class="no" v-if="!exporting">عرض التفاصيل</th>
          </thead>
          <tbody>
            <tr v-for="report in reports" v-bind:key="report._id">
              <td>
                {{ report.name }}
              </td>
              <td>
                {{ report.reports[1].length }}
              </td>
              <td>
                {{ report.reports[2].length }}
              </td>
              <td>
                {{ report.reports[3].length }}
              </td>
              <td>
                {{ converMinutes(report.lates) }} 
              </td>
              <td class="no text-center" v-if="!exporting">
                <button
                  v-b-modal.modal-1
                  class="btn btn-primary btn-sm"
                  @click="details(report)"
                >
                  عرض التفاصيل
                </button>
                <!-- <br />
                <button
                  style="position: relative; top: 2px"
                  class="btn btn-secondary btn-sm"
                  @click="askLate(report)"
                >
                  مسائلة تأخر
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- basic modal -->
    <b-modal
      id="modal-1"
      title="تفاصيل التقرير"
      ok-only
      ok-title="حسناً، شكراً لك"
    >
      <b-card-text>
        <div class="col-12 table-responsive">
          <table class="table table-bordered table-hover">
            <thead>
              <th>التاريخ</th>
              <th>الحالة</th>
            </thead>
            <tbody>
              <tr v-for="a in r" v-bind:key="a._id">
                <td>
                  <strong>{{ a.date }}</strong>
                </td>
                <td>
                  <span class="badge badge-secondary" v-if="a.status == 0"
                    >خارج وقت الدوام</span
                  >
                  <span class="badge badge-success" v-if="a.status == 1"
                    >حاضر</span
                  >
                  <span class="badge badge-warning" v-if="a.status == 2"
                    >متأخر</span
                  >
                  <div v-if="a.status == 2 && a.late_time > 0">
                    متأخر: {{ a.late_time }} دقيقة ({{
                      (a.late_time / 60).toFixed(1)
                    }}
                    ساعة)
                  </div>
                  <span class="badge badge-danger" v-if="a.status == 3"
                    >غائب</span
                  >
                  <span class="badge badge-primary" v-if="a.status == 5"
                    >غائب بعذر</span
                  >
                  <span class="badge badge-info" v-if="a.status == 6"
                    >اجازة</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
var moment = require("moment-hijri");
import {
  BCard,
  BCardText,
  BLink,
  BDropdown,
  VBModal,
  BDropdownItem,
} from "bootstrap-vue";

var XLSX = require("xlsx");
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
const axios = require("axios");
export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      table: false,
      loading: false,
      reports: [],
      r: [],
      info: {},
      selected_group: null,
      groups: [],
      exporting: false,
      user: JSON.parse(localStorage.getItem('user')),
    };
  },
  methods: {
    converMinutes(totalMinutes){
        var hours = Math.floor(totalMinutes / 60);          
        var minutes = totalMinutes % 60;
        return (`${hours == 1 ? 'ساعة' : (hours == 2 ? 'ساعتان' : hours)} ${hours > 2 ? 'ساعات' : ''}` + (minutes>0 ? ' و' + minutes + ' دقيقة' : '') );
    },
    exportExcel(){
        const wb = XLSX.utils.book_new(), g = this;
            const Heading = [
                [
                    "م",
                    "الاسم",
                    "	عدد أيام الحضور",
                    "ايام التأخر	",
                    "أيام الغياب",
                    "ساعات التأخر"
                ]
            ];
            var xx = [];
            g.reports.forEach(function(a, i){
                xx.push([
                    i+1,
                    a.name,
                    a.reports[1].length,
                    a.reports[2].length,
                    a.reports[3].length,
                    (a.lates/60).toFixed(1)
                ])
            })
            
            // creating sheet and adding data from 2nd row of column A.
            // leaving first row to add Heading
            const ws = XLSX.utils.json_to_sheet(xx, { origin: 'A2', skipHeader: true });
                
            // adding heading to the first row of the created sheet.
            // sheet already have contents from above statement.
            XLSX.utils.sheet_add_aoa(ws, Heading, { origin: 'A1' });
                
            // appending sheet with a name
            XLSX.utils.book_append_sheet(wb, ws, 'Records');
                
            const fileContent = XLSX.writeFile(wb, $("title").first().text().split("—") + ".xlsx");
    },
    viewReport() {
      var g = this,
        _g = this;
      if (this.$refs.from.value == "" || this.$refs.to.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        this.loading = true;
        this.table = true;
        axios
          .post(api + "/user/teachers/reports/between", {
            jwt: this.user.jwt,
            from: this.$refs.from.value,
            to: this.$refs.to.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              g.reports = r.data.response.map(function(x){
                x.lates = 0;
                x.reports[2].forEach(function(a){
                  if(a.late_time > 0){
                    x.lates = x.lates + a.late_time;
                  }
                })
                return x;
              });
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    details(reportx) {
      var arr = [];
      for (const [status, value] of Object.entries(reportx.reports)) {
        value.forEach(report => {
          if(report?.date){
            arr.push(report)
          }
        });
      }
      console.log(arr)
      this.r = arr;
    },
    print() {
      var printWindow = window.open("", "");
      printWindow.document.write(
        "<html lang='ar'><head dir='rtl'><title>tahdir.net</title>"
      );

      //Print the Table CSS.
      printWindow.document.write('<style type = "text/css">');
      printWindow.document.write(`
         body
          {
              font-family: Arial;
              font-size: 10pt;
              direction: rtl;
          }
          table
          {
              border: 1px solid #ccc;
              border-collapse: collapse;
              width: 100%
          }
          table th
          {
              background-color: #F7F7F7;
              color: #333;
              font-weight: bold;
          }
          table th, table td
          {
              padding: 5px;
              border: 1px solid #ccc;
          }
          .no {
            display: none;
          }
        `);
      printWindow.document.write("</style>");
      printWindow.document.write("</head>");
      moment.locale("ar-SA");
      var days = [
        "اﻷحد",
        "اﻷثنين",
        "الثلاثاء",
        "اﻷربعاء",
        "الخميس",
        "الجمعة",
        "السبت",
      ];
      var d = new Date(this.$refs.from.value);
      var start_dayname = days[d.getDay()];
      var start_date =
        moment(this.$refs.from.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      d = new Date(this.$refs.to.value);
      var to_dayname = days[d.getDay()];
      var to_date =
        moment(this.$refs.to.value, "YYYY-M-D").format("iYYYY/iM/iD") + "هـ";
      //Print the DIV contents i.e. the HTML Table.
      printWindow.document.write(`<body>
      <div style='width: 100%; padding: 20px;'>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
            المملكة العربية السعودية
            <br><br>
            وزارة التعليم
            <br><br>
            ${this.user.name}
          </h5>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <img src='https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg' style='width: 50%'>
          <br>
          <h2>تقرير حضور وغياب وتأخر المعلمين لفترة</h2>
        </div>
        <div style='width: 30%; text-align: center; display: inline-block'>
          <h3>
          بداية من: ${start_dayname} الموافق ${start_date}
          <br><br>
          إلى: ${to_dayname} الموافق ${to_date}
          </h3>
        </div>
      </div>
      `);
      var divContents = this.$refs.table.innerHTML;
      printWindow.document.write(divContents);
      printWindow.document.write(
        "<br><center><strong>تم استخراج التقرير بواسطة التحضير الذكي www.tahdir.net</strong></center></body>"
      );

      printWindow.document.write("</html>");
      printWindow.document.close();
      setTimeout(() => {
        printWindow.print();
      }, 1000);
    },
    askLate(report) {
      var _g = this;
      _g.$router.push(
        "/reports/ask-late-between/" +
          report.arr[0].hdate.year +
          "-" +
          report.arr[0].hdate.month +
          "-" +
          report.arr[0].hdate.day +
          "/" +
          report.arr[report.arr.length - 1].hdate.year +
          "-" +
          report.arr[report.arr.length - 1].hdate.month +
          "-" +
          report.arr[report.arr.length - 1].hdate.day +
          "/" +
          report.late_time +
          "/" +
          report._id +
          "/" +
          new Date().toISOString().split("T")[0]
      );
    },
    ga() {
      var g = this,
        _g = this;
      if (this.$refs.from.value == "" || this.$refs.to.value == "") {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "برجاء اختيار التاريخ",
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      } else {
        this.loading = true;
        this.table = true;
        var arr = [];
        axios
          .post(api + "/user/teachers/reports/between", {
            jwt: g.user.jwt,
            from: this.$refs.from.value,
            to: this.$refs.to.value,
          })
          .then(function (r) {
            if (r.data.status == 100) {
              g.loading = false;
              r.data.response.forEach((element) => {
                if (element.settings_group == g.selected_group) {
                  arr.push(element);
                }
              });
              g.reports = arr;
            } else {
              g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ 2",
                  icon: "TimesIcon",
                  variant: "danger",
                },
              });
            }
          })
          .catch(function () {
            g.loading = false;
            g.table = false;
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  created() {
      if(!checkPer("teachers|reports|between")){
          this.$router.push('/NotPermitted')
          return false;
      }
    var _g = this;

    axios
      .post(api + "/user/teachers/groups", {
        jwt: _g.user.jwt,
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.groups = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
